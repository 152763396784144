var columns = [{
  title: "ID",
  dataIndex: "id",
  key: "id",
  width: "10%",
  scopedSlots: {
    customRender: "id"
  }
}, {
  title: "申请时间",
  dataIndex: "createtime",
  key: "createtime",
  width: "15%",
  scopedSlots: {
    customRender: "createtime"
  }
}, {
  title: "申请人昵称",
  dataIndex: "applyusernickname",
  key: "applyusernickname",
  width: "10%",
  scopedSlots: {
    customRender: "applyusernickname"
  }
}, {
  title: "交易团新名称",
  dataIndex: "applyname",
  key: "applyname",
  width: "15%",
  scopedSlots: {
    customRender: "applyname"
  }
}, {
  title: "交易团原名称",
  dataIndex: "groupname",
  key: "groupname",
  width: "10%",
  scopedSlots: {
    customRender: "groupname"
  }
}, {
  title: "状态",
  dataIndex: "examinstatus",
  key: "examinstatus",
  width: "15%",
  scopedSlots: {
    customRender: "examinstatus"
  }
}, {
  title: "操作",
  key: "action",
  dataIndex: "action",
  //   fixed: "right",
  width: "20%",
  scopedSlots: {
    customRender: "action"
  }
}];
export { columns };